import axios from "axios";
import { QueryClient } from "react-query";

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await axios.get(
    `${process.env.NEXT_PUBLIC_API_BASE_URL}${queryKey[0]}`
  );
  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});
