import * as Sentry from "@sentry/nextjs";
import pkg from "../../package.json";

const metaData = {
  version: pkg.version,
};

// flush issue to sentry as error issues.
const sentryFlushError = (message: any, error: Error, data: any = null) => {
  Sentry.withScope((scope) => {
    scope.setLevel("error");
    scope.setContext("metadata", metaData);
    scope.setContext("data", data);
    scope.setContext("message", { message });
    Sentry.captureException(error);
  });
};

// flush issue to sentry as warning issues.
const sentryFlushWarn = (message: any, error: Error, data: any = null) => {
  Sentry.withScope((scope) => {
    scope.setLevel("warning");
    scope.setContext("metadata", metaData);
    scope.setContext("data", data);
    scope.setContext("message", { message });
    Sentry.captureException(error);
  });
};

// add message to sentry breadcrumbs
const sentryBreadcrumb = (
  type: string,
  category: string,
  level: Sentry.SeverityLevel,
  message: string,
  data: any
) => {
  Sentry.withScope((scope) => {
    scope.addBreadcrumb({
      type,
      level,
      category,
      message,
      data,
    });
  });
};

const logInfo = (message: string, data: any = null) => {
  // eslint-disable-next-line no-console
  console.log(`info: ${message}`);
  sentryBreadcrumb("info", "logger", "info", message, {
    metaData,
    data,
  });
};

const logDebug = (message: string, data: any = null) => {
  // eslint-disable-next-line no-console
  console.log(`debug: ${message}`, data);
  sentryBreadcrumb("debug", "logger", "debug", message, {
    metaData,
    data,
  });
};

const logError = (message: any, data: any = null, error: Error = null) => {
  // eslint-disable-next-line no-console
  console.error(`error: ${message}`);
  const errorObj = error instanceof Error ? error : message;
  sentryFlushError(message, errorObj, data);
};

const logWarn = (message: any, data: any = null, error: Error = null) => {
  // eslint-disable-next-line no-console
  console.error(`warning: ${message}`);
  const errorObj = error instanceof Error ? error : message;
  sentryFlushWarn(message, errorObj, data);
};

export { logInfo, logDebug, logError, logWarn };
