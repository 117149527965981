import { i18n } from "../../next-i18next.config";

export const isPath = (checkPath, currentPath) =>
  new RegExp(`${checkPath}(/?)$`).test(currentPath);

export const getPath = (currentPath) => {
  const res = /^(\/?(?<lang>th|en|id)?(?<path>\/?.*)(\/?))$/.exec(currentPath);
  if (!res) throw new Error("Invalid path");

  return {
    lang: res.groups?.lang || i18n.defaultLocale,
    path: res.groups?.path || "/",
  };
};

export const pushPathCreator = (router) => (path) => {
  const { lang } = getPath(router.asPath);
  router.push(`/${lang}${path}`);
};

export const getHref = (href, locale) => {
  if (href.startsWith("http")) {
    return href;
  }

  return `/${locale}${href}`;
};
