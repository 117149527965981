const path = require("path");

const defaultLocale = "default";
const supportedLocaleList = JSON.parse(
  process.env.SUPPORTED_LOCALE_LIST || '["default","en"]'
);

module.exports = {
  i18n: {
    defaultLocale,
    locales: supportedLocaleList,
    localeDetection: false,
  },
  localePath: path.join(__dirname, "public/static/locales"),
  react: { useSuspense: false },
  trailingSlash: true,
};
